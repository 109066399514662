// const baseURL = 'https://localhost:44379/api/';
 const baseURL = `https://pratishthanvisitapi.sahajayogaindia.org.in/api/`;

export const LOGIN_URL = `${baseURL}Account/login`;
export const COUNTRY_URL = `${baseURL}Masters/getcountries`;
export const GET_STATE_BY_COUNTRY_ID = `${baseURL}Masters/getstatesbycountryid`;
export const GET_DISTRICT_BY_STATE_ID = `${baseURL}Masters/getdistrictsbystateid`;
export const ADD_OTHER_STATE = `${baseURL}Masters/addotherstate`;
export const ADD_OTHER_DISTRICT = `${baseURL}Masters/addotherdistrict`;
export const ADD_UPDATE_VISITER = `${baseURL}Visiters/addupdatevisiter`;
export const SEND_MAIL = `${baseURL}Visiters/sendmail`;

export default LOGIN_URL;