/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect } from "react";
// import { useNavigate } from 'react-router-dom';
// @mui material components
// import Card from "@mui/material/Card";
// Material Dashboard 2 React components
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";
// import MDButton from "components/MDButton";
// @mui icons
// import Icon from "@mui/material/Icon";
// Authentication layout components
 import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
 import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
 import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import BasicLayout from "layouts/authentication/components/BasicLayout";
// Images
// import bgImage from "assets/images/bg-sign-in-basic.jpeg";
// import { jsPDF } from 'jspdf';
// import html2canvas from "html2canvas";
// import * as moment from 'moment';
// import { Oval } from 'react-loader-spinner';
// import * as service from '../../../../services/service';

function Thanks() {
    // const [loading, setLoading] = useState(false);
    // const navigate = useNavigate();    

    useEffect(() => {

    }, []);


    // const submitHandler = () => {
    //     navigate('/authentication/sign-in/basic');
    // };

    return (
        <div style={{ width: '100%' }}>
           <br />          
                {/* <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={3}
                    mt={3}
                    p={3}
                    mb={3}
                    textAlign="center"
                > */}
                    <Table style={{backgroundColor: "#006a4e", color: 'white'}}>
                        <TableRow>
                            <TableCell>
                            Thank you for your registration. <br/> You will receive an email regarding the approval/rejection of your registration soon. <br/><br/> Jai Shri Mataji.
                            </TableCell>
                        </TableRow>
                    </Table>                    
                {/* </MDBox> */}
            
        </div>
    );
}

export default Thanks;
