/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Card from "@mui/material/Card";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment';
import { Stack, Checkbox } from "@mui/material";

import Select from "react-select";
import { Oval } from 'react-loader-spinner';
import validator from "validator";
import * as service from '../../../../services/service';

function Basic() {
  const [state, setState] = useState(
    {
      firstname: '', middlename: '', lastname: '', center: '', emailid: '', mobileno: '', passportno: '', pan: '', adharcardno: '',
      coordinatorname: '', coordinatorcontact: '', address: '', otherdistrict: '', otherstate: ''
    });
  const [birthDate, setBirthDate] = useState(new Date());
  const [arrivalDate, setArrivalDate] = useState(new Date());
  const [departureDate, setDepartureDate] = useState(new Date());
  const [countryId, setCountryId] = useState('');
  const [countryData, setCountryData] = useState([]);
  const [stateId, setStateId] = useState('');
  const [stateData, setStateData] = useState([]);
  const [districtId, setDistrictId] = useState('');
  const [districtData, setDistrictData] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [gender, setGender] = useState('');
  const [arrivalTime, setArrivalTime] = useState('');
  const [departureTime, setDepartureTime] = useState('');
  const [selfRealizationYear, setSelfRealizationYear] = useState('');
  const [visiterType, setVisiterType] = useState('');
  const [declaration, setDeclaration] = useState(true);
  const genderData = [{ label: "Male", value: "Male" }, { label: "Female", value: "Female" }];
  const visterTypeData = [{ label: "Adult", value: "Adult" }, { label: "Yuva", value: "Yuvashakti" }, { label: "Bal", value: "Balshakti" }];
  const yearList = [
    { label: "2023", value: "2023" }, { label: "2022", value: "2022" },
    { label: "2021", value: "2021" }, { label: "2020", value: "2020" },
    { label: "2019", value: "2019" }, { label: "2018", value: "2018" },
    { label: "2017", value: "2017" }, { label: "2016", value: "2016" },
    { label: "2015", value: "2015" }, { label: "2014", value: "2014" },
    { label: "2013", value: "2013" }, { label: "2012", value: "2012" },
    { label: "2011", value: "2011" }, { label: "2010", value: "2010" },
    { label: "2009", value: "2009" }, { label: "2008", value: "2008" },
    { label: "2007", value: "2007" }, { label: "2006", value: "2006" },
    { label: "2005", value: "2005" }, { label: "2004", value: "2004" },
    { label: "2003", value: "2003" }, { label: "2002", value: "2002" },
    { label: "2001", value: "2001" }, { label: "2000", value: "2000" },
    { label: "1999", value: "1999" },
    { label: "1998", value: "1998" }, { label: "1997", value: "1997" },
    { label: "1996", value: "1996" }, { label: "1995", value: "1995" },
    { label: "1994", value: "1994" }, { label: "1993", value: "1993" },
    { label: "1992", value: "1992" }, { label: "1991", value: "1991" },
    { label: "1990", value: "1990" }, { label: "1989", value: "1989" },
    { label: "1988", value: "1988" }, { label: "1987", value: "1987" },
    { label: "1986", value: "1986" }, { label: "1985", value: "1985" },
    { label: "1984", value: "1984" }, { label: "1983", value: "1983" }];

  const timeData = [
    { label: "4 AM", value: "4 AM" }, { label: "4.30 AM", value: "4.30 AM" },
    { label: "5 AM", value: "5 AM" }, { label: "5.30 AM", value: "5.30 AM" },
    { label: "6 AM", value: "6 AM" }, { label: "6.30 AM", value: "6.30 AM" },
    { label: "7 AM", value: "7 AM" }, { label: "7.30 AM", value: "7.30 AM" },
    { label: "8 AM", value: "8 AM" }, { label: "8.30 AM", value: "8.30 AM" },
    { label: "9 AM", value: "9 AM" }, { label: "9.30 AM", value: "9.30 AM" },
    { label: "10 AM", value: "10 AM" }, { label: "10.30 AM", value: "10.30 AM" },
    { label: "11 AM", value: "11 AM" }, { label: "11.30 AM", value: "11.30 AM" },
    { label: "12 PM", value: "12 PM" }, { label: "12.30 PM", value: "12.30 PM" },
    { label: "1 PM", value: "1 PM" }, { label: "1.30 PM", value: "1.30 PM" },
    { label: "2 PM", value: "2 PM" }, { label: "2.30 PM", value: "2.30 PM" },
    { label: "3 PM", value: "3 PM" }, { label: "3.30 PM", value: "3.30 PM" },
    { label: "4 PM", value: "4 PM" }, { label: "4.30 PM", value: "4.30 PM" },
    { label: "5 PM", value: "5 PM" }, { label: "5.30 PM", value: "5.30 PM" },
    { label: "6 PM", value: "6 PM" }, { label: "6.30 PM", value: "6.30 PM" },
    { label: "7 PM", value: "7 PM" }, { label: "7.30 PM", value: "7.30 PM" },
    { label: "8 PM", value: "8 PM" }, { label: "8.30 PM", value: "8.30 PM" },
    { label: "9 PM", value: "9 PM" }, { label: "9.30 PM", value: "9.30 PM" },
    { label: "10 PM", value: "10 PM" }, { label: "10.30 PM", value: "10.30 PM" },
    { label: "11 PM", value: "11 PM" }, { label: "11.30 PM", value: "11.30 PM" },
    { label: "12 AM", value: "12 AM" }, { label: "12.30 AM", value: "12.30 AM" }];

  const [otherVisiters, setOtherVisiters] = useState([]);

  const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

  const navigate = useNavigate();

  const getCountries = () => {
    fetch(
      service.COUNTRY_URL,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })
      .then(res => res.json())
      .then(result => {
        const data2 = result.map((item) => ({ value: item.countryId, label: item.country }));
        setCountryData(data2);
      });
  };
  const getStates = (id) => {
    fetch(
      service.GET_STATE_BY_COUNTRY_ID,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          CountryId: id,
        })
      })
      .then(res => res.json())
      .then(result => {
        const data2 = result.map((item) => ({ value: item.stateId, label: item.state }));
        setStateData(data2);
      });
  };

  const getDistricts = (id) => {
    fetch(
      service.GET_DISTRICT_BY_STATE_ID,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          StateId: id,
        })
      })
      .then(res => res.json())
      .then(result => {
        const data2 = result.map((item) => ({ value: item.districtId, label: item.district }));
        setDistrictData(data2);
      });
  };

  useEffect(() => {
    getCountries();
    const def = { value: 1, label: 'India' };
    setCountryId(def);
    getStates(def.value);

    const gen = { label: 'Male', value: 'Male' };
    setGender(gen);
  }, []);

  const handleDeclarationCheckboxChange = (checked) => {
    setDeclaration(checked);
  }

  const validate = () => {
    let isValid = true;
    const regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    const regadhar = /^[0-9]{4}[ -]?[0-9]{4}[ -]?[0-9]{4}$/;
    // const regmobile = /^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){10}\d$/;
    const errs = {};
    if (!state.firstname) {
      isValid = false;
      errs[".firstname"] = "Enter first name";
    }
    if (!state.lastname) {
      isValid = false;
      errs[".lastname"] = "Enter last name";
    }
    if (!state.address) {
      isValid = false;
      errs[".address"] = "Enter address";
    }
    if (!visiterType) {
      isValid = false;
      errs[".visiterType"] = "Select type";
    }
    if (!stateId && !state.otherstate) {
      isValid = false;
      errs[".state"] = "Please select state or enter state if its not present in the list";
    }
    if (!districtId && !state.otherdistrict) {
      isValid = false;
      errs[".district"] = "Please select district or enter district if its not present in the list";
    }
    if (!state.emailid) {
      isValid = false;
      errs[".emailId"] = "Enter emailId";
    }
    if (state.emailid && !validator.isEmail(state.emailid)) {
      isValid = false;
      errs[".emailId"] = "Enter Valid emailId";
    }
    if (!state.mobileno) {
      isValid = false;
      errs[".mobileno"] = "Enter mobile no.";
    }
    if (state.mobileno && !validator.isMobilePhone(state.mobileno)) {
      isValid = false;
      errs[".mobileno"] = "Enter Valid mobile no.";
    }
    // if (state.mobileno && state.mobileno.length > 10) {
    //   isValid = false;
    //   errs[".mobileno"] = "Enter Valid mobile no.";
    // }
    // if (state.mobileno && state.mobileno.length < 10) {
    //   isValid = false;
    //   errs[".mobileno"] = "Enter Valid mobile no.";
    // }
    if (countryId.value === 1 && !state.pan) {
      isValid = false;
      errs[".pan"] = "Please enter PAN";
    }
    if (countryId.value === 1 && state.pan && !regpan.test(state.pan)) {
      isValid = false;
      errs[".pan"] = "Please enter valid PAN";
    }
    if (countryId.value === 1 && !state.adharcardno) {
      isValid = false;
      errs[".adharcardno"] = "Enter Adhar Card No.";
    }
    if (countryId.value === 1 && state.adharcardno && !regadhar.test(state.adharcardno)) {
      isValid = false;
      errs[".adharcardno"] = "Enter Valid Adhar Card No.";
    }
    if (countryId.value !== 1 && !state.passportno) {
      isValid = false;
      errs[".passportno"] = "Enter passport no.";
    }
    if (!selfRealizationYear) {
      isValid = false;
      errs[".selfRealizationYear"] = "Select self realization year";
    }
    if (!state.center) {
      isValid = false;
      errs[".center"] = "Enter center";
    }
    if (!state.coordinatorname) {
      isValid = false;
      errs[".coordinatorname"] = "Enter center coordinator name and email id";
    }
    if (!state.coordinatorcontact) {
      isValid = false;
      errs[".coordinatorcontact"] = "Enter center coordinator contact";
    }
    if (!arrivalTime) {
      isValid = false;
      errs[".arrivalTime"] = "Select arrival time";
    }
    if (!departureTime) {
      isValid = false;
      errs[".departureTime"] = "Select departure time";
    }
    if (arrivalDate > departureDate) {
      isValid = false;
      errs[".departureDate"] = "Select proper departure date";
    }
    if (otherVisiters.length > 0) {
      otherVisiters.forEach(element => {
        if (!element.firstname) {
          isValid = false;
          errs[".elefirstname"] = "Enter first name";
        }
        if (!element.lastname) {
          isValid = false;
          errs[".elelastname"] = "Enter last name";
        }
        // if (!element.emailid) {
        //   isValid = false;
        //   errs[".eleemailid"] = "Enter email id";
        // }
        // if (!element.mobileno) {
        //   isValid = false;
        //   errs[".elemobileno"] = "Enter mobile no";
        // }
        if (!element.gender) {
          isValid = false;
          errs[".elegender"] = "Select Gender";
        }
        if (element.mobileno && !validator.isMobilePhone(element.mobileno)) {
          isValid = false;
          errs[".elemobileno"] = "Enter Valid mobile no.";
        }
        // if (element.mobileno && element.mobileno.length > 10) {
        //   isValid = false;
        //   errs[".elemobileno"] = "Enter Valid mobile no.";
        // }
        // if (element.mobileno && element.mobileno.length < 10) {
        //   isValid = false;
        //   errs[".elemobileno"] = "Enter Valid mobile no.";
        // }
        if (countryId.value === 1 && !element.adharcardno) {
          isValid = false;
          errs[".eleadharcardno"] = "Enter adhar card no";
        }
        if (countryId.value === 1 && element.adharcardno && !regadhar.test(element.adharcardno)) {
          isValid = false;
          errs[".eleadharcardno"] = "Enter Valid Adhar Card No.";
        }
        // if (countryId.value === 1 && !element.pan) {
        //   isValid = false;
        //   errs[".elepan"] = "Enter pan";
        // }
        if (countryId.value === 1 && element.pan && !regpan.test(element.pan)) {
          isValid = false;
          errs[".elepan"] = "Please enter valid PAN";
        }
        if (countryId.value !== 1 && !element.passportno) {
          isValid = false;
          errs[".elepassportno"] = "Enter passport no";
        }
        if (!element.visitertype) {
          isValid = false;
          errs[".elevisitertype"] = "Select type";
        }
      });
    }
    setErrors(errs);
    return isValid;
  }
  const changeHandler = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const countryChangeHandler = (ev) => {
    setCountryId(ev);
    setStateId(null);
    setDistrictId(null);
    getStates(ev.value);
  }
  const stateChangeHandler = (ev) => {
    setStateId(ev);
    setDistrictId(null);
    getDistricts(ev.value);
  }
  const districtChangeHandler = (ev) => {
    setDistrictId(ev);
  }
  const visiterTypeChangeHandler = (ev) => {
    setVisiterType(ev);
  }
  const selfRealizationYearChangeHandler = (ev) => {
    setSelfRealizationYear(ev);
  }

  const arrivalTimeChangeHandler = (ev) => {
    setArrivalTime(ev);
  }
  const departureTimeChangeHandler = (ev) => {
    setDepartureTime(ev);
  }
  function SendMail() {
    setLoading(true);
    let maleCount = 0;
    let femaleCount = 0;
    if (otherVisiters.length > 0) {
      const male = otherVisiters.filter(obj => obj.gender.value === 'Male');
      maleCount = male.length;

      const female = otherVisiters.filter(ele => ele.gender.value === 'Female');
      femaleCount = female.length;
    }

    if (gender.value === 'Male') {
      maleCount += 1;
    }
    else {
      femaleCount += 1;
    }
    fetch(
      service.SEND_MAIL,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          FirstName: state.firstname,
          MiddleName: state.middlename,
          LastName: state.lastname,
          Address: state.address,
          Country: countryId.label,
          State: stateId ? stateId.label : state.otherstate,
          District: districtId ? districtId.label : state.otherdistrict,
          Center: state.center,
          MobileNo: state.mobileno,
          EmailId: state.emailid,
          Gender: gender.value,
          PAN: state.pan,
          CenterCoordinatorContactNo: state.coordinatorcontact,
          CenterCoordinatorName: state.coordinatorname,
          AdharCardNo: state.adharcardno,
          PassportNo: state.passportno,
          BirthDate: moment(birthDate).format("MM/DD/YYYY"),
          ArrivalDate: moment(arrivalDate).format("MM/DD/YYYY"),
          DepartureDate: moment(departureDate).format("MM/DD/YYYY"),
          ArrivalTime: arrivalTime.value,
          DepartureTime: departureTime.value,
          SelfRealizationYear: selfRealizationYear.value,
          VisiterType: visiterType.value,
          MemberCount: otherVisiters.length > 0 ? otherVisiters.length + 1 : 1,
          MaleCount: maleCount,
          FemaleCount: femaleCount
        })
      })
      .then(res => res.json())
      .then(result => {
        if (result.messageType === 'Failed') {
          // eslint-disable-next-line
          alert(result.message);
        }
      });
    setLoading(false);
  }

  function AddOtherVisiters(groupLeaderid, stid, distid) {
    let flag = true;
    setLoading(true);
    otherVisiters.forEach(element => {
      fetch(
        service.ADD_UPDATE_VISITER,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            VisiterId: 0,
            FirstName: element.firstname,
            MiddleName: element.middlename,
            LastName: element.lastname,
            Address: '',
            CountryId: countryId.value,
            StateId: stid,
            DistrictId: distid,
            Center: state.center,
            MobileNo: element.mobileno,
            EmailId: element.emailid,
            Gender: element.gender.value,
            PAN: element.pan,
            CenterCoordinatorContactNo: state.coordinatorcontact,
            CenterCoordinatorName: state.coordinatorname,
            AdharCardNo: element.adharcardno,
            PassportNo: element.passportno,
            BirthDate: moment(element.birthdate).format("MM/DD/YYYY"),
            ArrivalDate: moment(arrivalDate).format("MM/DD/YYYY"),
            DepartureDate: moment(departureDate).format("MM/DD/YYYY"),
            ArrivalTime: arrivalTime.value,
            DepartureTime: departureTime.value,
            SelfRealizationYear: '0',
            VisiterType: element.visitertype.value,
            Status: 'Pending',
            GroupLeaderId: groupLeaderid,
            AddedBy: groupLeaderid,
            UpdatedBy: 0,
            Search: ''
          })
        })
        .then(res => res.json())
        .then(result => {
          if (result.message.messageType === 'Failed') {
            flag = false;
            // eslint-disable-next-line
            alert(result.message.message);
          }
        });
    });
    if (flag) {
      SendMail();
      // eslint-disable-next-line
      alert("Data Saved Successfully");
      navigate('/thanks');
    }
    setLoading(false);
  }

  const AddVisiter = e => {
    e.preventDefault();
    setLoading(true);
    if (validate()) {
      if (!stateId && state.otherstate) {
        fetch(
          service.ADD_OTHER_STATE,
          {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              State: state.otherstate,
              CountryId: countryId.value,
              AddedBy: 0,
              UpdatedBy: 0,
            })
          })
          .then(stt => stt.json())
          .then(stat => {
            if (stat.messageType === 'Success') {
              if (!districtId && state.otherdistrict) {
                fetch(
                  service.ADD_OTHER_DISTRICT,
                  {
                    method: 'POST',
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                      District: state.otherdistrict,
                      CountryId: countryId.value,
                      StateId: stat.id,
                      AddedBy: 0,
                      UpdatedBy: 0,
                    })
                  })
                  .then(dis => dis.json())
                  .then(dist => {
                    if (dist.messageType === 'Success') {
                      fetch(
                        service.ADD_UPDATE_VISITER,
                        {
                          method: 'POST',
                          headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                          },
                          body: JSON.stringify({
                            VisiterId: 0,
                            FirstName: state.firstname,
                            MiddleName: state.middlename,
                            LastName: state.lastname,
                            Address: state.address,
                            CountryId: countryId.value,
                            StateId: stat.id,
                            DistrictId: dist.id,
                            Center: state.center,
                            MobileNo: state.mobileno,
                            EmailId: state.emailid,
                            Gender: gender.value,
                            PAN: state.pan,
                            CenterCoordinatorContactNo: state.coordinatorcontact,
                            CenterCoordinatorName: state.coordinatorname,
                            AdharCardNo: state.adharcardno,
                            PassportNo: state.passportno,
                            BirthDate: moment(birthDate).format("MM/DD/YYYY"),
                            ArrivalDate: moment(arrivalDate).format("MM/DD/YYYY"),
                            DepartureDate: moment(departureDate).format("MM/DD/YYYY"),
                            ArrivalTime: arrivalTime.value,
                            DepartureTime: departureTime.value,
                            SelfRealizationYear: selfRealizationYear.value,
                            VisiterType: visiterType.value,
                            Status: 'Pending',
                            GroupLeaderId: 0,
                            AddedBy: 0,
                            UpdatedBy: 0,
                            Search: 'Search'
                          })
                        })
                        .then(res => res.json())
                        .then(result => {
                          if (result.message.messageType === 'Success') {
                            AddOtherVisiters(result.visiterId, stat.id, dist.id);
                          }
                          else if (result.message.messageType === 'Failed') {
                            // eslint-disable-next-line
                            alert(result.message.message);
                          }
                          else if (result.message.messageType === 'Exists') {
                            // eslint-disable-next-line
                            alert(`Your Registration is already submitted for the period from ${moment(result.arrivalDate).format("DD-MM-YYYY")} to ${moment(result.departureDate).format("DD-MM-YYYY")}. Kindly check your email`);
                          }
                        });
                    }
                    else {
                      // eslint-disable-next-line
                      alert(result.message.message);
                    }
                  });
              }
            }
            else {
              // eslint-disable-next-line
              alert(result.message.message);
            }
          });
      }
      else if (!districtId && state.otherdistrict) {
        fetch(
          service.ADD_OTHER_DISTRICT,
          {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              District: state.otherdistrict,
              CountryId: countryId.value,
              StateId: stateId.value,
              AddedBy: 0,
              UpdatedBy: 0,
            })
          })
          .then(dis => dis.json())
          .then(dist => {
            if (dist.messageType === 'Success') {
              fetch(
                service.ADD_UPDATE_VISITER,
                {
                  method: 'POST',
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    VisiterId: 0,
                    FirstName: state.firstname,
                    MiddleName: state.middlename,
                    LastName: state.lastname,
                    Address: state.address,
                    CountryId: countryId.value,
                    StateId: stateId.value,
                    DistrictId: dist.id,
                    Center: state.center,
                    MobileNo: state.mobileno,
                    EmailId: state.emailid,
                    Gender: gender.value,
                    PAN: state.pan,
                    CenterCoordinatorContactNo: state.coordinatorcontact,
                    CenterCoordinatorName: state.coordinatorname,
                    AdharCardNo: state.adharcardno,
                    PassportNo: state.passportno,
                    BirthDate: moment(birthDate).format("MM/DD/YYYY"),
                    ArrivalDate: moment(arrivalDate).format("MM/DD/YYYY"),
                    DepartureDate: moment(departureDate).format("MM/DD/YYYY"),
                    ArrivalTime: arrivalTime.value,
                    DepartureTime: departureTime.value,
                    SelfRealizationYear: selfRealizationYear.value,
                    VisiterType: visiterType.value,
                    Status: 'Pending',
                    GroupLeaderId: 0,
                    AddedBy: 0,
                    UpdatedBy: 0,
                    Search: 'Search'
                  })
                })
                .then(res => res.json())
                .then(result => {
                  if (result.message.messageType === 'Success') {
                    AddOtherVisiters(result.visiterId, stateId.value, dist.id);
                  }
                  else if (result.message.messageType === 'Failed') {
                    // eslint-disable-next-line
                    alert(result.message.message);
                  }
                  else if (result.message.messageType === 'Exists') {
                    // eslint-disable-next-line
                    alert(`Your Registration is already submitted for the period from ${moment(result.arrivalDate).format("DD-MM-YYYY")} to ${moment(result.departureDate).format("DD-MM-YYYY")}. Kindly check your email`);
                  }
                });
            }
            else {
              // eslint-disable-next-line
              alert(result.message.message);
            }
          });
      }
      else {
        fetch(
          service.ADD_UPDATE_VISITER,
          {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              FirstName: state.firstname,
              MiddleName: state.middlename,
              LastName: state.lastname,
              Address: state.address,
              CountryId: countryId.value,
              StateId: stateId.value,
              DistrictId: districtId.value,
              Center: state.center,
              MobileNo: state.mobileno,
              EmailId: state.emailid,
              Gender: gender.value,
              PAN: state.pan,
              CenterCoordinatorContactNo: state.coordinatorcontact,
              CenterCoordinatorName: state.coordinatorname,
              AdharCardNo: state.adharcardno,
              PassportNo: state.passportno,
              BirthDate: moment(birthDate).format("MM/DD/YYYY"),
              ArrivalDate: moment(arrivalDate).format("MM/DD/YYYY"),
              DepartureDate: moment(departureDate).format("MM/DD/YYYY"),
              ArrivalTime: arrivalTime.value,
              DepartureTime: departureTime.value,
              SelfRealizationYear: selfRealizationYear.value,
              VisiterType: visiterType.value,
              Status: 'Pending',
              GroupLeaderId: 0,
              AddedBy: 0,
              UpdatedBy: 0,
              Search: 'Search'
            })
          })
          .then(res => res.json())
          .then(result => {
            if (result.message.messageType === 'Success') {
              AddOtherVisiters(result.visiterId, stateId.value, districtId.value);
            }
            else if (result.message.messageType === 'Failed') {
              // eslint-disable-next-line
              alert(result.message.message);
            }
            else if (result.message.messageType === 'Exists') {
              // eslint-disable-next-line
              alert(`Your Registration is already submitted for the period from ${moment(result.arrivalDate).format("DD-MM-YYYY")} to ${moment(result.departureDate).format("DD-MM-YYYY")}. Kindly check your email`);
            }
          });
      }
    }
    else {
      // eslint-disable-next-line
      alert("Please enter all the mandatory fields.")
    }
    setLoading(false);
  }

  const handleFormChange = (index, event, type) => {
    const data = [...otherVisiters];
    if (type === 'birthdate') {
      data[index].birthdate = new Date(event);
    }
    else if (type === 'gender') {
      data[index].gender = event;
    }
    else if (type === 'visitertype') {
      data[index].visitertype = event;
    }
    else {
      data[index][event.target.name] = event.target.value;
    }
    setOtherVisiters(data);
  }

  const addFields = () => {
    const newfield = {
      firstname: '', middlename: '', lastname: '', emailid: '', mobileno: '', passportno: '', pan: '', adharcardno: '', birthdate: new Date(),
      gender: { label: 'Male', value: 'Male' }, visitertype: ''
    }
    setOtherVisiters([...otherVisiters, newfield])
  }

  const removeFields = (index) => {
    const data = [...otherVisiters];
    data.splice(index, 1)
    setOtherVisiters(data)
  }

  return (
    <div style={{ width: '100%' }}>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Book your visit to Pratishthan Pune by providing the following details
                </MDTypography>
              </MDBox>
              <Card style={{ alignItems: 'center' }}>
                <br />
                <div alignSelf="center" style={{ fontSize: '14px', color: 'red', fontWeight: 'bold' }}>Please use laptop/desktop for registration</div>
                <TableContainer component={Paper} style={{ width: '80%', alignSelf: 'center' }}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ width: '30%' }}>
                          <MDInput label="First Name" fullWidth value={state.firstname || ''}
                            name="firstname"
                            onChange={changeHandler}
                            id="firstname"
                            required />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".firstname"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ width: '30%' }}>
                          <MDInput label="Middle Name" fullWidth value={state.middlename || ''}
                            name="middlename"
                            onChange={changeHandler}
                            id="middlename"
                          />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".middlename"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <MDInput label="Last Name" fullWidth value={state.lastname || ''}
                            name="lastname"
                            onChange={changeHandler}
                            id="lastname"
                            required />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".lastname"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ width: '35%' }}>
                          <MDInput label="Address" fullWidth value={state.address || ''}
                            name="address"
                            onChange={changeHandler}
                            id="address"
                            required />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".address"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Select
                            options={countryData}
                            name="country"
                            value={countryId}
                            onChange={countryChangeHandler}
                            placeholder="Select Country"
                            isSearchable
                            styles={selectStyles}
                          />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".country"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Stack direction="row" spacing={5}>
                            <div style={{ width: '50%' }}>
                              <Select
                                options={stateData}
                                name="state"
                                value={stateId}
                                onChange={stateChangeHandler}
                                placeholder="Select State"
                                isSearchable
                                styles={selectStyles}
                              />
                            </div>
                            <MDInput label="Other" value={state.otherstate || ''}
                              name="otherstate"
                              onChange={changeHandler}
                              id="otherstate" />
                          </Stack>
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".state"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Stack direction="row" spacing={5}>
                            <div style={{ width: '50%' }}>
                              <Select className="basic-single"
                                options={districtData}
                                name="district"
                                value={districtId}
                                onChange={districtChangeHandler}
                                isSearchable
                                placeholder="Select District"
                                styles={selectStyles}
                              />
                            </div>
                            <MDInput label="Other" value={state.otherdistrict || ''}
                              name="otherdistrict"
                              onChange={changeHandler}
                              id="otherdistrict" />
                          </Stack>
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".district"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <MDInput label="Mobile (Whats App) No" fullWidth value={state.mobileno || ''}
                            name="mobileno"
                            onChange={changeHandler}
                            id="mobileno"
                            required
                          />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".mobileno"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <MDInput label="Email Id" fullWidth value={state.emailid || ''}
                            name="emailid"
                            type="email"
                            onChange={changeHandler}
                            id="emailid"
                            required
                          />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".emailId"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Dropdown menuClassName='myMenuClassName' id="gender"
                            name="gender"
                            options={genderData}
                            value={gender}
                            onChange={ev => setGender(ev)}
                            placeholder="Select Gender"
                          />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gender"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <MDInput label="PAN" fullWidth value={state.pan || ''}
                            name="pan"
                            onChange={changeHandler}
                            id="pan"
                            required
                            disabled={countryId.value !== 1}
                          />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".pan"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <MDInput label="Adhar Card No" fullWidth value={state.adharcardno || ''}
                            name="adharcardno"
                            onChange={changeHandler}
                            id="adharcardno"
                            required
                            disabled={countryId.value !== 1}
                          />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".adharcardno"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <MDInput label="Passport No" fullWidth value={state.passportno || ''}
                            name="passportno"
                            onChange={changeHandler}
                            id="passportno"
                            required
                            disabled={countryId.value === 1}
                          />
                          <div style={{ fontSize: '12px', color: 'green' }}>For Overseas Visitors, Please Carry 2 Photocopies of your Passport and Visa with you</div>
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".passportno"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Dropdown menuClassName='myMenuClassName' id="visiterType"
                            name="visiterType"
                            options={visterTypeData}
                            value={visiterType}
                            onChange={visiterTypeChangeHandler}
                            placeholder="Type"
                          />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".visiterType"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Select
                            options={yearList}
                            name="selfRealizationYear"
                            value={selfRealizationYear}
                            onChange={selfRealizationYearChangeHandler}
                            placeholder="Select Self Realization Year"
                            isSearchable
                            styles={selectStyles}
                          />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".selfRealizationYear"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <MDInput label="Attending Meditation Center" fullWidth value={state.center || ''}
                            name="center"
                            onChange={changeHandler}
                            id="center"
                            required
                          />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".center"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <MDInput label="Coordinator Name And Email Id" fullWidth value={state.coordinatorname || ''}
                            name="coordinatorname"
                            onChange={changeHandler}
                            id="coordinatorname"
                          />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".coordinatorname"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <MDInput label="Coordinator Mobile No" fullWidth value={state.coordinatorcontact || ''}
                            name="coordinatorcontact"
                            onChange={changeHandler}
                            id="coordinatorcontact"
                          />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".coordinatorcontact"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Birth Date
                          <br />
                          <DatePicker
                            name="birthDate"
                            selected={birthDate}
                            onChange={date => setBirthDate(date)}
                            dateFormat="MM/dd/yyyy"
                            maxDate={new Date()}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="scroll"
                            yearDropdownItemNumber={80}
                            peekNextMonth
                          />
                          <div style={{ fontSize: '12px', color: 'green' }}>MM/DD/YYYY</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Arrival Date at Pratishthan
                          <DatePicker
                            name="arrivalDate"
                            selected={arrivalDate}
                            onChange={date => setArrivalDate(date)}
                            dateFormat="MM/dd/yyyy"
                            minDate={new Date()}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="scroll"
                            peekNextMonth
                          />
                          <div style={{ fontSize: '12px', color: 'green' }}>MM/DD/YYYY</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Arrival Time at Pratishthan
                          <Select
                            options={timeData}
                            name="arrivalTime"
                            value={arrivalTime}
                            onChange={arrivalTimeChangeHandler}
                            placeholder="Select Time"
                            isSearchable
                            styles={selectStyles}
                          />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".arrivalTime"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Departure Date from Pratishthan
                          <DatePicker
                            name="departureDate"
                            selected={departureDate}
                            onChange={date => setDepartureDate(date)}
                            minDate={arrivalDate}
                            dateFormat="MM/dd/yyyy"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="scroll"
                            peekNextMonth
                          />
                          <div style={{ fontSize: '12px', color: 'green' }}>MM/DD/YYYY</div>
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".departureDate"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Departure Time from Pratishthan
                          <Select
                            options={timeData}
                            name="departureTime"
                            value={departureTime}
                            onChange={departureTimeChangeHandler}
                            placeholder="Select Time"
                            isSearchable
                            styles={selectStyles}
                          />
                          <div style={{ fontSize: '12px', color: 'red' }}>{errors[".departureTime"]}</div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan="3" >
                          <hr />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" style={{ fontSize: '14px', fontWeight: 'bold', color: 'green' }}>
                          For more than 10 members, please send all the member/visiter details (name, gender, adhar card no and birthdate) on the following Email Id <br /> pratishthanpunendsy@gmail.com
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan="3" align="center" style={{ fontSize: '14px', fontWeight: 'bold' }}>
                          Accompanying Member Details (Up to 10 Members)
                          <Table>
                            {otherVisiters.map((input, index) => (
                              <TableRow
                                key={input.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell>
                                  <Table>
                                    <TableRow>
                                      <TableCell style={{ fontSize: '12px', width: '30%' }}>
                                        <MDInput label="First Name"
                                          fullWidth value={input.firstname}
                                          name="firstname"
                                          onChange={event => handleFormChange(index, event, 'firstname')}
                                          required
                                        />
                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".elefirstname"]}</div>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell style={{ fontSize: '12px', width: '30%' }}>
                                        <MDInput label="Middle Name"
                                          fullWidth value={input.middlename}
                                          name="middlename"
                                          onChange={event => handleFormChange(index, event, 'middlename')}
                                        />
                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".elemiddlename"]}</div>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell style={{ fontSize: '12px', width: '30%' }}>
                                        <MDInput label="Last Name"
                                          fullWidth value={input.lastname}
                                          name="lastname"
                                          onChange={event => handleFormChange(index, event, 'lastname')}
                                          required
                                        />
                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".elelastname"]}</div>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell style={{ fontSize: '12px' }}>
                                        <Dropdown menuClassName='myMenuClassName' id="gender"
                                          name="gender"
                                          options={genderData}
                                          value={input.gender}
                                          onChange={event => handleFormChange(index, event, 'gender')}
                                          placeholder="Select Gender"
                                        />
                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".elegender"]}</div>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell style={{ fontSize: '12px', display: 'none' }}>
                                        <MDInput label="Email Id"
                                          fullWidth value={input.emailid}
                                          name="emailid"
                                          onChange={event => handleFormChange(index, event, 'emailid')}
                                        />
                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".eleemailid"]}</div>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell style={{ fontSize: '12px', display: 'none' }}>
                                        <MDInput label="Mobile No"
                                          fullWidth value={input.mobileno}
                                          name="mobileno"
                                          onChange={event => handleFormChange(index, event, 'mobileno')}
                                        />
                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".elemobileno"]}</div>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell style={{ fontSize: '12px', display: 'none' }}>
                                        <MDInput label="PAN"
                                          fullWidth value={input.pan}
                                          name="pan"
                                          onChange={event => handleFormChange(index, event, 'pan')}
                                          disabled={countryId.value !== 1}
                                        />
                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".elepan"]}</div>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell style={{ fontSize: '12px' }}>
                                        <MDInput label="Adhar Card No"
                                          fullWidth value={input.adharcardno}
                                          name="adharcardno"
                                          onChange={event => handleFormChange(index, event, 'adharcardno')}
                                          disabled={countryId.value !== 1}
                                          required
                                        />
                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".eleadharcardno"]}</div>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell style={{ fontSize: '12px' }}>
                                        <MDInput label="Passport"
                                          fullWidth value={input.passportno}
                                          name="passportno"
                                          onChange={event => handleFormChange(index, event, 'passportno')}
                                          disabled={countryId.value === 1}
                                        />
                                        <div style={{ fontSize: '12px', color: 'green' }}>For Overseas Visitors, Please Carry 2 Photocopies of your Passport and Visa with you</div>
                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".elepassportno"]}</div>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell style={{ fontSize: '12px' }}>
                                        Birth Date
                                        <DatePicker
                                          name="birthdate"
                                          selected={input.birthdate}
                                          onChange={date => handleFormChange(index, date, 'birthdate')}
                                          dateFormat="MM/dd/yyyy"
                                          maxDate={new Date()}
                                          showMonthDropdown
                                          showYearDropdown
                                          dropdownMode="scroll"
                                          yearDropdownItemNumber={80}
                                          peekNextMonth
                                        />
                                        <div style={{ fontSize: '12px', color: 'green' }}>MM/DD/YYYY</div>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell style={{ fontSize: '12px' }}>
                                        <Dropdown menuClassName='myMenuClassName' id="visiterType"
                                          name="visiterType"
                                          options={visterTypeData}
                                          value={input.visitertype}
                                          onChange={event => handleFormChange(index, event, 'visitertype')}
                                          placeholder="Type"
                                        />
                                        <div style={{ fontSize: '12px', color: 'red' }}>{errors[".elevisitertype"]}</div>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell style={{ fontSize: '12px' }}>
                                        <MDButton color="secondary" onClick={() => removeFields(index)}>
                                          Remove
                                        </MDButton>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell colSpan="3" >
                                        <hr />
                                      </TableCell>
                                    </TableRow>
                                  </Table>
                                </TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell style={{ fontSize: '12px' }}>
                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".othervisiter"]}</div>
                                <MDButton color="secondary" onClick={addFields}>
                                  Add Accompanying Member Details
                                </MDButton>
                              </TableCell>
                            </TableRow>
                          </Table>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontSize: '14px', fontWeight: 'bold' }}>
                          Note : In Pratishthan, Ladies and Gents have separate stay arrangements
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontSize: '14px', fontWeight: 'bold' }}>
                          Self Declaration :
                          I declare that
                          <br />
                          1. The purpose of visiting Pratishthan Pune is for Sahajayoga Meditation.
                          <br />
                          2. I/We respect Pratishthan as our Holy Mother`s Abode and will follow all the protocols.
                          <br />
                          5. I/We will maintain the Decorum and Cleanliness of Pratishthan.
                          <br />
                          <Checkbox checked={declaration || false} value={declaration}
                            onChange={(e) => handleDeclarationCheckboxChange(e.target.checked)}
                            id="chkDeclaration" />
                          I Accept
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <MDBox mt={4} mb={1} textAlign="center">
                  {loading ? <Oval
                    heigth="20"
                    width="20"
                    color='grey'
                    ariaLabel='loading'
                  /> :
                    <MDButton color="info" onClick={AddVisiter} disabled={!declaration}>
                      Save
                    </MDButton>
                  }
                  {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                </MDBox>

              </Card>
            </Card>
          </Grid >
        </Grid >
      </MDBox >
      <Footer />
    </div >
  );
}
export default Basic;
